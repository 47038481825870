import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { detailquestion_seperate } from "../../../api/actions/detailQuestion";
import desktop from "../../Home/banner/assets/images/desktops.png";
import { IconContext } from "react-icons";
import queryString from "query-string";
import ShareLink from "react-facebook-share-link";
import { FaFacebook } from "react-icons/fa";
import { TwitterShareButton, TwitterIcon } from "react-share";
export default function Shared({ location }) {
  console.log(location);
  const [detailquestion, setdeatailquestion] = useState({});
  const [loading, isloading] = useState(true);

  useEffect(() => {
    if (!!queryString.parse(location.search).id) {
      (async () => {
        const result = await detailquestion_seperate(
          queryString.parse(location.search).id
        );

        setdeatailquestion(result);

        isloading(false);
      })();
    }
  }, []);

  return (
    <div className="detail-answer">
      <div id="fb-root"></div>

      <div className="content">
        {loading ? (
          <Skeleton count="5" />
        ) : (
            <div>
              <div className="share-media">
                <div className="img-sharebox">
                  <ShareLink
                    link={
                      "https://fertility.medanswers.com/questions/detail?id=" +
                      detailquestion.id
                    }
                  >
                    {(link) => (
                      <a href={link} target="_blank" className="facebook-share">
                        <IconContext.Provider
                          value={{
                            color: "#fff",
                            className: "global-class-name",
                          }}
                        >
                          <div>
                            <FaFacebook />
                          </div>
                        </IconContext.Provider>{" "}
                        {/* Share */}
                      </a>
                    )}
                  </ShareLink>
                  {/* <div className="share-tweet">
                  <a
                    href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                    className="twitter-share-button "
                    data-text={
                      detailquestion.description.length > 240
                        ? detailquestion.description.substring(0, 240) + "..."
                        : detailquestion.description
                    }
                    data-size="large"
                    data-url={
                      "https://fertility.medanswers.com/questions/detail?id=" +
                      detailquestion.id
                    }
                    data-show-count="false"
                  >
                    Tweet
                  </a>
                </div> */}
                  <TwitterShareButton
                    url={
                      "https://fertility.medanswers.com/questions/detail?id=" +
                      _id
                    }
                    title={title > 240 ? title.substring(0, 240) + "..." : title}
                    className="Demo__some-network__share-button"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <h2>
                    Answers to all of your
                  <br />
                  Fertility Questions
                </h2>
                  <img src={desktop} alt="fertility answers web application" />
                </div>
                <div className="description">
                  <h3>
                    Share copy is the Question in bold and the answer below.
                </h3>
                  <p> -2 line limit for question</p>
                  <p> -1 line limit for answer</p>
                </div>
                <div className="question-box">
                  <h5>MEDANSWERS.COM</h5>
                  <h1>{detailquestion.description}</h1>
                  <p className="eclipse-p">
                    {detailquestion.best_answer != null
                      ? detailquestion.best_answer.description
                      : detailquestion.answers.length > 0
                        ? detailquestion.answers[0].description
                        : null}
                  </p>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
