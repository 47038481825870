import React from "react";
import Layout from "../../components/layout/layout";
import SEO from "../../components/layout/seo";
import Share from "../../components/Questions/detailsanswer/share.js";

export default function Shared({ location }) {
  return (
    <Layout location={location}>
      <SEO title="answer share" />
      <Share location={location} />
    </Layout>
  );
}
